<template>
  <div
    v-if="Object.keys(site).length"
    class="nav-wrap"
  >
    <div class="nav flex-center-between relative z2">
      <div class="flex items-center nav-logo">
        <!--     左侧logo区   -->
        <a href="/">
          <img
            class="cp"
            :style="isMobile ? 'height: 28px;' : 'height: 35px;'"
            :src="site.logo"
            alt=""
          >
          <span
            :style="isMobile ? 'height: 18px; margin-top:6px;' : ''"
          ></span>
          <p
            :style="isMobile ? 'height: 28px; line-height:28px; font-size:16px; margin-top:1px;' : ''"
          >{{ site.footerlogoTag }} </p>
        </a>
        <!--     右侧菜单区   -->
        <a-menu
          v-if="!isMobile"
          v-model="current"
          mode="horizontal"
          style="border:none"
        >
          <a-menu-item
            v-for="(item) in nav"
            :key="item.path.substring(1)"
            @click="handleTo(item, '')"
            :class="hasChildren(item.children) ? 'navtitle' : 'navtitle'"
            @mouseenter="showSubMenu(item)"
            @mouseleave="closeSubMenu(item)"
            style="font-size: 18px; color: #000;"
          >
            {{ item.title }}

<!--        判断是否有子菜单-->
            <div
              :id="'subid' + item.id"
              v-if="hasChildren(item.children) && JSON.parse(item.showChildren)"
              style="position: absolute; background: #fff;margin-left: -20px; margin-top: 2px;"
            >
              <div style="padding: 0 20px 0 25px; "
                   class="navtitle-sub"
                v-for="submenu in item.children"
                :key="submenu.uri.substring(1)"
                   @click="handleTo(item, submenu.uri)"
              >
                {{submenu.name}}
              </div>
            </div>

          </a-menu-item>
        </a-menu>
      </div>

      <!--     移动端菜单入口   -->
      <div class="flex-center">
          <img
            v-if="isMobile"
            class="ml10"
            :src="showMobileNav ? site.icon.close : site.icon.more"
            alt=""
            @click="showMobileNav = !showMobileNav"
          >

      </div>
    </div>
    <!--     移动端菜单列表   -->
    <div
      v-if="showMobileNav"
      class="nav__mobile absolute l0 width-100 bg-fff z1"
    >
      <div
        v-for="(item, i) in nav"
        :key="i"
        @click="handleTo(item, '')"
        style="line-height:60px"
        class="pl10 bd bb bd-f2"
      >
        {{ item.title }}

        <div
          v-if="hasChildren(item.children)"
          style="float: right; margin-right: 15px; width: 22px; ">
          <img :src="site.icon.down" style="width: 100%;">
        </div>

        <div
          :id="'subid' + item.id"
          v-if="hasChildren(item.children) "
          style="background: #fff; width: 100%;margin-left: -20px; margin-top: 1px;"
        >
          <div style="padding: 0 0 0 40px; font-size: 14px;  "
               class="navtitle-sub  bd bb bd-f2"
               v-for="submenu in item.children"
               :key="submenu.uri.substring(1)"
               @click="handleTo(item, submenu.uri)"
          >
            {{submenu.name}}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      current: [location.hash.substring(1)],
      nav: [],
      showMobileNav: false,
      // isShowSubMenu: false
    }
  },

  async created () {
    // console.log(this.current)
    this.nav = await this.fetch('/nav.json')
  },

  methods: {
    handleTo (item, uri) {



      this.showMobileNav = false;
      if (uri === ''){
        if (!this.hasChildren(item.children)){
          uri = item.path;
          if ('.' + this.$route.path !== uri){
            this.$router.push(uri)
          }
        }
      }else{
        if (this.$route.path !== uri){
          this.$router.push(uri)
        }
      }
    },

    toggleSubMenu(item) {
      if (this.hasChildren(item)) {
        this.$set(item, 'opened', !item.opened); // 切换当前菜单项的状态
      } else {
        // 处理没有子菜单时点击事件的逻辑
      }
    },

    hasChildren(item) {
      return Array.isArray(item) && item.length > 0;
    },

    showSubMenu(item){
      item.showChildren = true;
    },

    closeSubMenu(item){
      item.showChildren = false;
    }
  }
}
</script>

<style lang="less">
.nav-wrap {
  position: sticky!important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
}
.pc {
  @height: 80px;
  .nav {
    height: @height;
    box-shadow: 0 5px 5px rgba(0,0,0,.05);
    padding: 0 20px;
    .ant-menu-horizontal {
      line-height: @height;
      margin-left: @height;
    }
  }
  //.container {
  //  height: calc(100vh - 80px);
  //}
}
.mobile {
  @height: 50px;
  .nav {
    height: @height;
    box-shadow: 0 5px 5px rgba(0,0,0,.05);
    padding: 0 10px;
  }
  .nav__mobile {
    top: @height;
    padding-bottom: 80px;
  }
}
.ant-menu-item{ color: #333; }
.ant-menu-horizontal li.ant-menu-item:hover,
.ant-menu-horizontal > li.ant-menu-item-selected {
  //color: #ed7989!important;
  border-bottom: 2px solid #ed7989;
}

.navtitle:hover, .navtitle-sub:hover {
  color: #ed7989!important;
}

.navtitle-sub{
   color: #333;
  font-size: 16px;
}

.navtitle-sub:last-child{
  border: none;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu{
  margin-top: -2px;
}

.nav-logo{  }
.nav-logo a{ color: #333; display: flex;}
.nav-logo a p{ color: #666; height: 35px; line-height: 35px; font-size: 17px; }
.nav-logo a span {
  height: 21px;
  width: 1px;
  background: #b1b1b1;
  margin: 8px 5px 0 8px;
}
</style>
