import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index.vue'),
    meta:{
      title:'御茵时代-基因蛋白护理开创者'
    }
  },
  {
    path: '/chanpin',
    component: () => import('../views/chanpin.vue'),
    meta:{
      title:'产品解决方案-御茵时代官网'
    }
  },
  {
    path: '/quanrenyuan',
    component: () => import('../views/quanrenyuan.vue'),
    meta:{
      title:'智慧医疗-御茵时代官网'
    }
  },
  {
    path: '/zoujin',
    component: () => import('../views/zoujin.vue'),
    meta:{
      title:'走进御茵时代-御茵时代官网'
    }
  },
  {
    path: '/huiyuan',
    component: () => import('../views/huiyuan.vue'),
    meta:{
      title:'会员服务-御茵时代官网'
    }
  },
  {
    path: '/pinpai',
    component: () => import('../views/pinpai.vue'),
    meta:{
      title:'品牌DNA-御茵时代官网'
    }
  },
  {
    path: '/lianxi',
    component: () => import('../views/lianxi.vue'),
    meta:{
      title:'联系我们-御茵时代官网'
    }
  },
  {
    path: '/shouquan',
    component: () => import('../views/shouquan.vue'),
    meta:{
      title:'授权查询-御茵时代官网'
    }
  },
  {
    path: '/fangwei',
    component: () => import('../views/fangwei.vue'),
    meta:{
      title:'防伪查询-御茵时代官网'
    }
  },
  {
    path: '/sihu',
    component: () => import('../views/chanpin.vue'),
    meta:{
      title:'全新私密护理解决方案-御茵时代官网'
    }
  },
  {
    path: '/rsw',
    component: () => import('../views/chanpin-rsw.vue'),
    meta:{
      title:'全新妊娠纹解决方案-御茵时代官网'
    }
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition){
    //if判断可加可不加、根据自己需求
    //savedPosition当且仅当通过浏览器的前进/后退按钮触发时才可用
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }
  },
  routes,
  mode: 'history',
})

router.beforeEach((to, from,next) => {
  document.title = to.matched[0].meta.title;
  next();
});

// router.afterEach((to,from,next) => {
//   window.scrollTo(0,0);
// });

export default router;



