import Vue from 'vue'
import App from './app.vue'
import router from './routers'
import store from './stores'
import AntdVue from 'ant-design-vue/dist/antd'
import 'ant-design-vue/dist/antd.css'
import 'tcon'
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import CountTo from 'vue-count-to'
import VueAos from 'vue-aos'
import './animate.css'

Vue.use(VueAos)

SwiperCore.use([Navigation, Pagination])
Vue.config.productionTip = false
Vue.use(AntdVue)
Vue.component('count-to', CountTo)

Vue.mixin({
  data(){
    return {
      isMobile: /android|iphone/i.test(navigator.userAgent)
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    site() {
      return this.$root.siteData || {}
    }
  },
  created () {
  },
  methods: {
    fetch (url) {
      // 处理相对路径
      const handlePath = obj => {
        if (Array.isArray(obj)) {
          obj.forEach(item => {
            handlePath(item)
          })
        } else if (typeof obj === 'object') {
          Object.keys(obj).forEach(k => {
            if (typeof obj[k] === 'object') {
              handlePath(obj[k])
            } else if (obj[k].startsWith('/')) {
              // 修改为相对路径
              obj[k] = `.${obj[k]}`
            }
          })
        }
      }

      return window.fetch(`.${url}`)
        .then(res => res.json())
        .then(res => {
          handlePath(res)
          return res
        })
    }
  }
})

new Vue({
  router,
  store,
  mixins: [],
  data() {
    return {
      siteData: null
    }
  },
  created () {
    this.fetch('/site.json').then(res => {
      this.siteData = res
    })
  },
  render: h => h(App)
}).$mount('#app')
