<template>
  <div
    id="app"
    :class="isMobile ? 'mobile' : 'pc'"
  >
    <Nav />
    <router-view class="container oh" />
    <Footer />
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'

export default {
  components: {
    Nav,
    Footer
  }
}
</script>

<style lang="less">
body {
  color: #333!important;
}
:root {
  --swiper-navigation-color: #fff;
}
.swiper-container-horizontal {
  .swiper-pagination-bullet {
    display: inline-block;
    width: 10px;
    height: 5px;
    background: rgba(255,255,255,.8);
    border-radius: 0;
    cursor: pointer;
    &-active {
      width: 30px;
    }
  }
  .swiper-pagination-bullets {
    bottom: 18px!important;
  }
}
</style>
